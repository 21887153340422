import { useTheme } from "@emotion/react";
import {
  TextField,
  Button,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import { serverUrl } from "functions/values";

export default function ContactForm() {
  const { palette } = useTheme();

  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");
  const isNotMobileScreen = useMediaQuery("(min-width: 1000px)");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!success) return;
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, [success]);

  async function submitForm() {
    if (loading) return;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("message", message);

    setLoading(true);
    try {
      const response = await fetch(`${serverUrl}/pages/contact/form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          message,
        }),
      });
      if (response.ok) {
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: isNotMobileScreen ? "6vh 0 0" : "5vh 0 0",
      }}
    >
      <div style={{ color: palette.primary.main }}>
        {isGerman ? "Kontaktformular" : "Contact Form"}
      </div>
      <TextField
        label={isGerman ? "Nachname, Vorname" : "Surname, name"}
        type="text"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoComplete="on"
        required
      />
      <TextField
        label="Email"
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="on"
        required
      />
      <TextField
        label={isGerman ? "Handynummer (optional)" : "Phone number (optional)"}
        type="text"
        name="phoneNumber"
        value={phoneNumber}
        autoComplete="on"
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <TextField
        label={isGerman ? "Nachricht" : "Message"}
        type="text"
        name="message"
        multiline
        rows={6}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <Button
        variant="contained"
        onClick={submitForm}
        disabled={name === "" || email === "" || message === ""}
        fullWidth
        endIcon={
          loading && <CircularProgress size={"1.2rem"} color="inherit" />
        }
      >
        {isGerman ? "Abschicken" : "Submit"}
      </Button>

      <div
        style={{
          opacity: success ? "1" : "0",
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          {isGerman
            ? "Ihre Nachricht wurde gesendet."
            : "Your message has been sent."}
        </Alert>
      </div>
    </div>
  );
}
