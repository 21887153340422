import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  token: "",
  language: "german",
  isCookiesAccepted: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTheme: (state) => {
      state.mode = state.mode === "dark" ? "light" : "dark";
    },
    setLogin: (state, action) => {
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.token = "";
    },
    setLanguage: (state) => {
      state.language = state.language === "german" ? "english" : "german";
    },

    // Projects
    setProjectState: (state, action) => {
      state.projects = action.payload;
    },
    addProjectState: (state, action) => {
      let project = {};
      project.projectName = action.payload.formState.projectName;
      state.projects.push(project);
    },
    unlinkProject: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project._id !== action.payload.projectId
      );
    },

    // Cookies
    setCookiePreferences: (state, action) => {
      state.isCookiesAccepted = action.payload.isCookiesAccepted;
    },
  },
});

export const {
  setTheme,
  setLogin,
  setLogout,
  setLanguage,
  setProjectState,
  addProjectState,
  unlinkProject,
  setCookiePreferences,
} = authSlice.actions;
export default authSlice.reducer;
