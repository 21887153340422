import { Button } from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CropIcon from "@mui/icons-material/Crop";
import CropDialog from "./CropDialog";
import { useSelector } from "react-redux";
import { serverUrl } from "functions/values";

export default function ImageInput({
  images,
  setImages,
  edited = false,
  setImagesToDelete = false,
}) {
  const language = useSelector((state) => state.language);
  const isGerman = Boolean(language === "german");

  // Crop
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [croppingIndex, setCroppingIndex] = useState(null);
  // Crop

  const handleFileInput = (event) => {
    const fileList = event.target.files;
    const filesArray = Array.from(fileList);

    readAndDisplayImages(filesArray);
  };

  const readAndDisplayImages = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImages((prevImages) => [
          ...prevImages,
          { file, dataURL: e.target.result, edited },
        ]);
      };

      reader.readAsDataURL(file);
    });
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div>
      <CropDialog
        open={open}
        setOpen={setOpen}
        images={images}
        croppingIndex={croppingIndex}
        setImages={setImages}
        edited={edited}
        setImagesToDelete={setImagesToDelete}
      />

      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        fullWidth
      >
        {isGerman ? "Bild(er) hochladen" : "Upload Image(s)"}
        <VisuallyHiddenInput
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          multiple={true}
          onInput={handleFileInput}
        />
      </Button>
      {images.length > 1 && (
        <div>
          <Button
            onClick={() => {
              setImagesToDelete &&
                setImagesToDelete((prevFiles) => [
                  ...prevFiles,
                  ...images.map((image) => image.file.name),
                ]);
              setImages([]);
            }}
            variant="outlined"
            sx={{ marginTop: "1rem" }}
            fullWidth
            color="error"
            startIcon={<DeleteIcon />}
          >
            {isGerman ? "Alle Bilder Löschen" : "Delete all Images"}
          </Button>
        </div>
      )}
      {images.map((image, index) => (
        <div
          key={index}
          style={{ display: "flex", gap: "1rem", margin: "2rem 0" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Button
              onClick={() => {
                if (!image.edited) {
                  setImagesToDelete &&
                    setImagesToDelete((prevFiles) => [
                      ...prevFiles,
                      image.file.name,
                    ]);
                }
                const updatedImages = images.filter((_, i) => i !== index);
                setImages(updatedImages);
              }}
              color="error"
              variant="text"
              sx={{ margin: "0.5rem 0" }}
              startIcon={<ClearIcon />}
            >
              {isGerman ? "Bild Löschen" : "Delete Image"}
            </Button>
            <Button
              onClick={() => {
                handleOpen();
                setCroppingIndex(index);
              }}
              variant="text"
              sx={{ margin: "0.5rem 0" }}
              startIcon={<CropIcon />}
            >
              {isGerman ? "Bild Zu-schneiden" : "Crop Image"}
            </Button>
          </div>

          <div>
            <img
              src={image.dataURL || `${serverUrl}/assets/${image}`}
              alt={`uploaded-${index}`}
              style={{ width: "100%", height: "100%", display: "block" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
