import { useTheme, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ButtonNavbar({
  text,
  isDrawerOpen,
  setDrawerOpen = false,
  destination = false,
  width = "240px",
}) {
  const { palette } = useTheme();
  const buttonRef = useRef(null);
  const mode = useSelector((state) => state.mode);

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const height = "3rem";

  // Width detection
  const [textWidth, setTextWidth] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(0);
  const elementRef = useRef(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    findUnderlineWidth();

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("load", findUnderlineWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", findUnderlineWidth);
    };
  }, [windowSize, isDrawerOpen, text]);

  function findUnderlineWidth() {
    const measureTextWidth = (text, font, letterSpacing) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      // Add letter-spacing adjustment
      const adjustedWidth = metrics.width + (text.length - 1) * letterSpacing;
      return adjustedWidth;
    };

    if (elementRef.current) {
      const computedStyle = getComputedStyle(elementRef.current);
      const font = `${computedStyle.fontWeight} ${computedStyle.fontSize} ${computedStyle.fontFamily}`;
      const letterSpacing = parseFloat(computedStyle.letterSpacing) || 0;
      const transformedText = text.replace(/_/g, " ").toUpperCase();
      const width = measureTextWidth(transformedText, font, letterSpacing);
      setTextWidth(width);
      console.log(width);
      setButtonWidth(elementRef.current.offsetWidth);
    }
  }

  // Width detection

  const StyledDiv = styled("div")(({ theme }) => ({
    height,
    margin: "1.5rem",
    borderLeft: `1px solid ${
      mode === "dark" ? palette.neutral.main : palette.neutral.medium
    }`,
    borderRight: `1px solid ${
      mode === "dark" ? palette.neutral.main : palette.neutral.medium
    }`,
    "&:hover": {
      backgroundColor:
        mode === "dark"
          ? hexToRGBA(palette.neutral.dark, 0.6)
          : hexToRGBA(palette.neutral.dark, 0.6),
    },
    minWidth: "130px",
    width,
  }));

  // underline
  const Underline = styled("div")(({ theme }) => ({
    color: palette.neutral.light,
    transition: "color 0.3s ease",
    "&::before": {
      content: "''",
      position: "absolute",
      display: "block",
      width: `${textWidth * 1.5}px`,
      height: "2px",
      bottom: "25%",
      left: `${(buttonWidth - textWidth * 1.5) / 2 - 0.7}px`, //for some reason -0.7 will center it better
      backgroundColor: palette.primary.main,
      transform: "scaleX(0)",
      transition: "transform 0.3s ease",
    },
    "&:hover::before": {
      transform: "scaleX(0.7)",
    },
  }));

  let hyperlink = text;
  if (text === "über_uns") hyperlink = "about_us";
  if (text === "kontakt") hyperlink = "contact";
  if (text === "projekte") hyperlink = "projects";

  const handleClick = (e) => {
    if (setDrawerOpen !== false) {
      setTimeout(() => {
        setDrawerOpen(false);
      }, 400);
    }
    const button = buttonRef.current;

    let x = e.clientX - button.offsetLeft;
    let y = e.clientY - button.offsetTop;

    let ripple = document.createElement("span");
    ripple.style.backgroundColor = palette.primary.main;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;

    button.appendChild(ripple);

    setTimeout(function () {
      ripple.remove();
    }, 400);
  };

  return (
    <StyledDiv
      className="navbarButton"
      ref={buttonRef}
      onClick={(e) => handleClick(e)}
    >
      <NavLink
        id="navLinkUnderline"
        to={
          destination
            ? destination.replace(". ", "")
            : `/${hyperlink === "home" ? "" : hyperlink}`
        }
        style={{ textDecoration: "none", height }}
      >
        {({ isActive }) => (
          <Underline id={isActive ? "active" : ""}>
            <Typography
              sx={{
                height,
                paddingTop: "0.8rem",
              }}
              ref={elementRef}
            >
              {text.replace(/_/g, " ")}
            </Typography>
          </Underline>
        )}
      </NavLink>
    </StyledDiv>
  );
}
